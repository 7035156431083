.carousel-container {
    position: relative;
    /* max-width: 600px; */
    margin: auto;
  }
  
  .carousel {
    display: flex;
    overflow: hidden;
  }
  
  .carousel img {
    width: 100%;
    transition: transform 0.5s ease;
    padding: 10px;
    justify-content: center;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    cursor: pointer;
    font-size: 24px;
    width: 45px;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  