@font-face {
  font-family: SFMonoRegular;
  src: url("/src/fonts/sf-mono/SFMonoRegular.otf");
}

.card-header {
  /* color: #c8d2f4 !important; */
  font-family: SFMonoRegular;
  text-align: start;
  padding: 10px !important;
  display: flex;
  justify-content: space-between;
  width:275px;
  align-items: flex-start;
  margin-top: 10px;
}

.card-description {
  font-family: SFMonoRegular;
  background: none;
  font-size: 10.5px;
  padding: 20px;
}

.card-links {
  background:none;
  /* background-color: #0d203b; */
  
}

 .card-link {
    justify-content: end;
    background:none;
   color: #fff ;
    padding: 10px;
  }

  .fa-brands {
    scale: 1.3;
  }

  .fa-brands:hover {
    /* color: #ff7a70; */
    color: #3454D1;
  }

  .fa-solid:hover {
    /* color: #ff7a70; */
    color: #3454D1;
  }

.card-stacks {
  background: none;
  padding: 20px;
}

.card-stack {
  font-family: SFMonoRegular;
  background: none;
  font-size: 10.5px;
}

@media screen and (max-width: 760px) {
    .card-header {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 10px;
        font-size: 0.7rem;
        width: 200px;
      }

      .card-description {
        font-size: 0.5rem;
        padding: 10px;;
      }
      .card-stacks {
        padding-top: 5px;
      }

      .card-stack {
        font-size: 8px;
      }

}
