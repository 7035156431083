@font-face {
  font-family: SFMonoRegular;
  src: url("/src/fonts/sf-mono/SFMonoRegular.otf");
}

.about-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 50px;
  margin-left: 170px;
  margin-right: 30px;
  margin-top: 1px;
  margin-bottom: 300px;
}

.about-title {
  display: inline-flex;
  align-items: center;
  /* color: #c8d2f4; */
  color: #070707;
  font-size: calc(4px + 2vmin);
  margin-bottom: 15px;
  padding-right: 10px;
  width: max-content;
  min-width: 300px;
}

.about-title::before {
  content: "01.";
  font-family: SFMonoRegular;
  margin-right: 0.5rem;
  /* color: #ff7a70; */
  color: #3454D1;
  font-size: calc(8px + 1vmin);
}

.about-title:after {
  content: "";
  margin-left: 1.2rem;
  height: 1px;
  background-color: #8892af;
  width: 200px;
}

.about-content {
  grid-column: 1/2;
  grid-row: 1;
  max-width: 600px;
}

.skill-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  align-items: flex-start;
  padding: 0;
  font-family: SFMonoRegular;
  font-size: 14px;
}

.skill-item:before {
  content: "•";
  margin-right: 0.5rem;
  /* color: #ff7a70; */
  color: #3454D1;
}
.portrait {
  grid-column: 2/2;
  grid-row: 1;
  width: 400px;
  border-radius: 15px;
}

@media screen and (max-width: 760px) {
  .about-container {
    grid: auto/ auto/auto/auto;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 2fr);
    margin-left: 50px;
    margin-top: 250px;
    max-width: 760px;
    margin-bottom: 30px;
  }

  .about-content {
    grid-column: 1/2;
    grid-row: 1;
    width: 350px;
  }

  .about-title:after {
    content: "";
    margin-left: 1rem;
    align-items: center;
    height: 1px;
    background-color: #8892af;
    width: 300px;
  }

  .portrait {
    grid-area: 2 / 1 / span 2 / span 3;
    margin-left: 40px;
    width: 300px;
  }
}
