@font-face {
    font-family: SFMonoRegular;
    src: url("/src/fonts/sf-mono/SFMonoRegular.otf");
  }
  
  .contact-container {
    display: flex;
    flex-direction: column;
    column-gap: 50px;
    margin-left: 170px;
    margin-right: 20px;
    margin-top: 1px;
    margin-bottom: 200px;
    align-items: center;
  }
  
  .contact-title {
    display: inline-flex;
    align-items: center;
    /* color: #c8d2f4; */
    color: #070707;
    font-size: calc(4px + 2vmin);
    width: max-content;
    min-width: 300px;
    margin-left: 170px;
    margin-right: 30px;
    margin-top: 1px;
    margin-bottom: 50px;
  }
  
  .contact-title:before {
    content: "04.";
    font-family: SFMonoRegular;
    margin-right: 0.5rem;
    /* color: #ff7a70; */
    color: #3454D1;
    font-size: calc(8px + 1vmin);
  }
  
  .contact-title:after {
    content: "";
    margin-left: 1.2rem;
    height: 1px;
    background-color: #8892af;
    width: 200px;
  }
  
  .contact-content {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    max-width: 600px;
    align-items: center;
    margin-bottom: 35px;
  }

  .contact-links {
    display: flex;
    justify-content: center;
    align-content: space-between;
    padding-top: 5px;
  }

  .contact-link {
    margin: 12px;
  }

  
  
  @media screen and (max-width: 760px) {
    .contact-title {
      margin-left: 50px;
    }
    .contact-container {
      grid: auto/ auto/auto/auto;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(2, 2fr);
      margin-left: 75px;
      margin-top: 50px;
      /* max-width: 760px; */
      margin-bottom: 30px;
    }
  
    .about-content {
      grid-column: 1/2;
      grid-row: 1;
    }
  
    .about-title:after {
      content: "";
      margin-left: 1rem;
      align-items: center;
      height: 1px;
      background-color: #8892af;
      width: 300px;
    }
  }
  