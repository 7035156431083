@font-face {
  font-family: SFMonoRegular;
  src: url("/src/fonts/sf-mono/SFMonoRegular.otf");
}

.navBarItems {
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  font-family: SFMonoRegular;
  padding-right: 30px;
}

.navbar-logo {
  /* color: #ff7a70; */
  color: #3454D1;
  justify-self: start;
  margin-left: 25px;
  text-decoration: none;
}

.nav-menu ul {
  display: grid;
  grid-template-columns: repeat(6, auto);
  width: 90vw;
  grid-gap: 20px;
  justify-content: end;
  text-align: end;
  align-items: center;
  list-style: none;
  margin: 0;
}

.nav-links {
  position: relative;
  padding: 5px;
  /* color: #c8d2f4; */
  color: #070707;
  text-decoration: none;
  font-size: 14px;

}

.nav-links:hover {
  /* color: #ff7a70; */
  color: #3454D1;
}

.menu-icon {
    display: none;
}


 @media screen and (max-width: 760px) {
    .navBarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu {
      position: absolute;
      /* top: 165px; */
      left: 0px;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 78px);
      justify-content:inherit;
      display: none;
    }

    .nav-menu.expanded ul {
      display: block;
      justify-items: center;
      text-align: center;
    }

    .nav-links {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
        text-decoration: none;
    }
    .nav-menu.expanded {
        display: block;
        text-align: center;
        padding: 0.5rem;
        border-radius: 4px;
        width: 100%;
        
    }
    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
    }
    /* Button {
        align-items: center;
        justify-items: center;
    } */
} 
