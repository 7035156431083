@font-face {
  font-family: SFMonoRegular;
  src: url("/src/fonts/sf-mono/SFMonoRegular.otf");
}

.hero-container {
  margin-left: 170px;
  margin-right: 30px;
  margin-top: 100px;
  margin-bottom: 350px;
}

.heading {
  /* color: #ff7a70; */
  color: #3454D1;
  font-family: SFMonoRegular;
  font-size: clamp(var(--fz-sm), 4vw, var(--fz-md));
}

.name {
  margin-bottom: 5px;
  /* color: #c8d2f4; */
  color: #070707;
  font-size: calc(10px + 8vmin);
  line-height: 0.9;
}

.subheading {
  margin-bottom: 25px;
  line-height: 0.9;
  color: #3454D1;
  font-size: calc(8px + 7vmin);
  max-width: 750px;
}

.about-me {
  max-width: 540px;
  line-height: 1.5;
}

.app-link {
  color: #ff7a70;
}

@media screen and (max-width: 760px) {
  .hero-container {
    margin-left: 50px;
    margin-bottom: 50px;
  }
}
