.featured-project-container {
  display: flex;
  flex-direction: column;
  margin-left: 170px;
  margin-right: 30px;
  margin-top: 1px;
  margin-bottom: 175px;
}

.featured-project-title::before {
  content: "02.";
  font-family: SFMonoRegular;
  margin-right: 0.5rem;
  /* color: #ff7a70; */
  color: #3454D1;
  font-size: calc(8px + 1vmin);
}

.featured-project-title {
  display: flex;
  align-items: center;
  /* color: #c8d2f4; */
  font-size: calc(4px + 2vmin);
  margin-bottom: 15px;
  padding-right: 10px;
  width: max-content;
  min-width: 300px;
}

.featured-project-title:after {
  content: "";
  margin-left: 1.2rem;
  height: 1px;
  background-color: #8892af;
  width: 200px;
}
.project-link {
  text-decoration: none;
  /* color: #c8d2f4; */
  color: #070707;
  font-weight: bold;
  max-width: 350px;
}

.project-link:hover {
  /* color: #ff7a70; */
  color: #3454D1;
}

/* .featured-project-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
} */

.featured-project-details {
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;
}

.featured-project-details-reverse {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  padding-top: 10px;

}


/* .featured-project-image {
  grid-column: 1/2;
  grid-row: 1;
  width: 100%;
  max-width: 55rem;
  height: auto;
} */

.featured-project-image-container {
  width: 50%;
  height: auto;
}

.featured-project-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
}


.featured-project-about {
  grid-column: 2/2;
  grid-row: 1;
  max-width: 25rem;
}

.project-stack {
  align-items: flex-start;
  padding: 0;
  
}

.project-stack ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  align-items: flex-start;
  padding: 0;
   font-family: SFMonoRegular;
  font-size: 14px;
}

.project-stack-item::before {
  content: "•";
  margin-right: 0.5rem;
  /* color: #ff7a70; */
  color: #3454D1;
}

@media screen and (max-width: 760px) {
  .featured-project-container {
    margin-left: 50px;
    margin-top: 50px;
    max-width: 760px;
  }

  .featured-project-details {
    display: grid;
  align-content: baseline;
    grid-template-columns: repeat(1, 1fr);
  }

  .featured-project-details-reverse {
    display: grid;
  align-content: baseline;
    grid-template-columns: repeat(1, 1fr);
  }

  .featured-project-image-container {
    padding-bottom: 10px;
  }

  .featured-project-image {
    grid-column: 1/1;
    grid-row: 1;
    width: 25rem;
    height: auto;
  }
  .featured-project-about {
    grid-column: 1/1;
    grid-row: 2;
  }
}
