@font-face {
  font-family: SFMonoRegular;
  src: url("/src/fonts/sf-mono/SFMonoRegular.otf");
}

.app-page-container {
  display: flex;
  flex-direction: column;
  margin-left: 45px;
  margin-right: 45px;
}

.app-page-container h2 {
  font-size: calc(10px + 5vmin);
  text-align: center;
}

.app-page-container p {
  text-align: center;
  font-family: SFMonoRegular;
}

.app-list-container {
  display: flex;
  margin-left: 75px;
  margin-right: 75px;
  margin-top: 25px;
  text-decoration: none;
  flex-wrap: wrap;
  align-items: center;
}

.app-link {
  text-decoration: none;
}

.app-container {
  font-family: SFMonoRegular;
  text-align: start;
  padding: 10px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  height: 300px;
  align-items: center;
  margin: 25px;
  background-color: #efefef;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  text-decoration: none;
}

.app-container h3 {
  text-decoration: none;
}

.app-container:hover {
  transform: scale(1.05);
}

.app-container:hover h3 {
  color: #3454d1 !important;
}

.app-header-image-container {
  width: 70%;
  height: auto;
  padding: 15px;
}

.app-header-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.app-details-container {
    display: flex;
    flex-direction: column;
    margin-left: 325px;
    margin-right: 325px;
    margin-bottom: 75px;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.back-button {
  display: inline-flex;
  padding-left: 30px;
}

.app-details-sub-header {
    display: inline-flex;
    align-items: center;
    font-family: SFMonoRegular;
    text-align: left;
    width: 100%;
    margin-bottom: 15px;
}

.app-details-sub-header:after {
    content: "";
    margin-left: 1.2rem;
    height: 1px;
    background-color: #8892af;
    width: 200px;
  }

.app-details-image-container {
  width: 40%;
  height: auto;
  padding: 15px;
}

.app-details-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.app-platforms-list {
    display: flex;
    list-style: none;
    padding: 0;
}

.app-platforms-list li {
font-family: SFMonoRegular;
font-size: 0.90rem;
margin-left: 10px;
margin-right: 10px;
}

.app-description {
    width: 90%;

}

.app-features-list {
    margin-left: 35px;
    margin-right: 35px;
    align-items: flex-start;
    width: 95%
}

.app-feature-item {
list-style: none;
text-indent: -18px;
margin-bottom: 5px;
}

.app-feature-item:before {
    content: "•";
    margin-right: 0.5rem;
    /* color: #ff7a70; */
    color: #3454D1;
}

.app-privacy-policy {
    width: 90%;
}

.app-store-link {
    width: 12rem;
    height: auto;
    padding-bottom: 8px;
  }

  .web-link {
    padding-bottom: 2rem;
  }

@media screen and (max-width: 760px) {
    .app-page-container {
        margin-left: 25px;
        margin-right: 25px;
      }

      .app-list-container {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 5px;
        flex-direction: column;

      }

      .app-details-container {
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 75px;
    }

    .app-features-list {
        margin-left: 7px;
        margin-right: 7px;
    }
}