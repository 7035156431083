@font-face {
  font-family: SFMonoRegular;
  src: url("/src/fonts/sf-mono/SFMonoRegular.otf");
}

.projects-container {
  column-gap: 50px;
  margin-left: 170px;
  margin-right: 30px;
  margin-top: 1px;
  margin-bottom: 300px;
}

.projects-title {
  display: inline-flex;
  align-items: center;
  /* color: #c8d2f4; */
  font-size: calc(4px + 2vmin);
  margin-bottom: 15px;
  padding-right: 10px;
  width: max-content;
  min-width: 300px;
}

.projects-title::before {
  content: "03.";
  font-family: SFMonoRegular;
  margin-right: 0.5rem;
  /* color: #ff7a70; */
  color: #3454D1;
  font-size: calc(8px + 1vmin);
}

.projects-title:after {
  content: "";
  margin-left: 1.2rem;
  height: 1px;
  background-color: #8892af;
  width: 200px;
}

.projects-content {
  text-align: center;
}

.projects-body {
    text-align: center;
    margin-bottom: 30px;
}

.project-cards {
    display:inline-grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    margin-left: 50px;
    margin-right: 50px;
    text-align: start;
   
}

.project-card {
    display: flex;
    /* background-color: #0d203b; */
    background-color: #EFEFEF;
    border-radius: 10px;
    /* border: 0.5px solid #070707; */
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    width: 275px;
    height: 275px;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    
}

.project-card:hover .card-header {
    /* color: #ff7a70 !important; */
    color: #3454D1 !important;
}



.project-card:hover {
   transform: scale(1.05);
}


@media screen and (max-width: 760px) {
  .projects-container {
    grid: auto/ auto/auto/auto;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 2fr);
    margin-left: 50px;
    margin-right: 20px;
    margin-top: 50px;
    max-width: 760px;
  }


  .project-cards {
    display: inline-grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0;
    margin-right: 0;
   
}

.projects-content {
    justify-items: center;
}


.project-card {
    display: flex;
    /* background-color: #0d203b; */
    border-radius: 5px;
    width: 185px;
    height: 185px;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    
}

  .projects-title:after {
    content: "";
    margin-left: 1rem;
    align-items: center;
    height: 1px;
    background-color: #8892af;
    width: 300px;
  }

  .project-cards {
    gap: 12px;
  }

}
