button {
    appearance: none;
    background-color: transparent;
    /* border: 2px solid #ff7a70; */
    border: 2px solid #3454D1;
    border-radius: 5px !important;
    box-sizing: border-box;
    color: #3454D1;
    cursor: pointer;
    display: inline-block;
    line-height: normal;
    min-height: 15px;
    min-width: 100px;
    outline: none;
    padding: 8px 15px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    user-select: none;
    touch-action: manipulation;
    width: min-content;
    will-change: transform;
    white-space: nowrap;
    font-family: SFMonoRegular;
  }
  
  button:disabled {
    pointer-events: none;
  }
  
  button:hover {
    color: #fff;
    background-color: #3454D1;;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  }
  
  button:active {
    box-shadow: none;
    transform: translateY(0);
  }